<div>
  <div class="modal-header p-3" *ngIf="modalTitle">
    <h4 class="modal-title text-center w-100 mb-0" id="modal-basic-title">{{modalTitle}}</h4>
    <img src="./assets/img/close-icon.svg" class="cursor-pointer" (click)="closeAction('cancel')">
  </div>
  <div class="modal-body p-5">
    <p class="text-center">{{modalText}}</p>
    <div class="modal-body-buttons mt-5">
      <oc-button *ngIf="showCancel"
                 type="secondary"
                 class="mr-sm-2 mb-2 mb-sm-0 mr-sm-0"
                 [text]="cancelButtonText"
                 (click)="closeAction(type === 'submission' ? 'draft' : 'cancel')">
      </oc-button>
      <oc-button type="primary"
                 [text]="buttonText"
                 [class]="type === 'delete' ? 'background-delete' : type === 'suspend' ? 'background-suspend' : ''"
                 (click)="closeAction('success')">
      </oc-button>
    </div>
  </div>
</div>
