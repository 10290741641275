<nav class="navbar navbar-expand-md navbar-light bg-white">
    <div class="container">
        <div class="navbar-wrapper">
            <a routerLink=""
               class="navbar-brand" (click)="closedMenu()">
                <img [src]="cmsData.headerLogoURL" alt="brand-logo" class="company-logo">
            </a>
            <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation"
                    (click)="isCollapsed = !isCollapsed">
                <div class="cursor-pointer" [ngClass]="{'navbar-icon': isCollapsed,'close-icon': !isCollapsed}">
                </div>
            </button>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent"
             [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav justify-content-end w-100 mb-0">
                <ng-container *ngFor="let headerItem of cmsData.headerItemsDFA">
                    <li class="nav-item"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">
                        <a [routerLink]="headerItem.location || ''"
                           (click)="closedMenu()"
                           class="nav-link cursor-pointer">{{headerItem.label}}
                            <span class="sr-only">(current)</span>
                        </a>
                    </li>
                </ng-container>
                <li class="nav-item collaps-none justify-content-between align-items-center"
                    *ngIf="authService.isLoggedInUser()"
                    [class.active]="checkIncludesUrl('/management/profile','/management/company')"
                    (click)="isMenuCollapsed = !isMenuCollapsed"
                    data-target="#collapsMoreContent"
                    aria-controls="collapsMoreContent">
                    <a class="nav-link cursor-pointer">More</a>
                    <div class="pr-3">
                        <svg-icon src="./assets/img/select-down.svg"
                                  [class.rotate-img]="!isMenuCollapsed"
                                  [svgClass]="checkIncludesUrl('/management/profile','/management/company')? '':'change-icon-color'">
                        </svg-icon>
                    </div>
                </li>
            </ul>

            <div class="collaps-items" *ngIf="authService.isLoggedInUser()">
                <div [ngbCollapse]="isMenuCollapsed" id="collapsMoreContent" class="collapse">
                    <ul class="navbar-nav ml-5">
                        <li class="nav-item">
                            <a *ngIf="!isSSO"
                               routerLink="/management/profile"
                               class="nav-link cursor-pointer"
                               (click)="closedMenu()">My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/management/company"
                               class="nav-link cursor-pointer"
                               (click)="closedMenu()"
                               [appPermissions]="companyPermissions">My Company</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link cursor-pointer" (click)="logout()">Logout</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="my-2 my-lg-0 ml-md-6 navbar-none" *ngIf="authService.isLoggedInUser()">
                <oc-profile-navbar username="More">
                    <ng-container>
                        <div class="dropdown-item cursor-pointer" *ngIf="!isSSO"
                             routerLink="/management/profile">My Profile</div>
                        <div [appPermissions]="companyPermissions" class="dropdown-item cursor-pointer"
                             routerLink="/management/company">My Company</div>
                        <div class="dropdown-item cursor-pointer" (click)="logout()">Logout</div>
                    </ng-container>
                </oc-profile-navbar>
            </div>
            <div *ngIf="!authService.isLoggedInUser()" class="d-flex my-2 my-lg-0 ml-0 ml-md-6 auth-button">
                <div class="d-flex auth-button">
                    <a class="btn header-login-btn header-btn font-med" routerLink="/login">Log in</a>
                    <a class="btn btn-primary header-btn ml-md-2 font-med" routerLink="/signup" *ngIf="!isSsoConfigExist">Sign up</a>
                </div>
            </div>
        </div>
    </div>
</nav>
