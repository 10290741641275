<div class="footer">
    <div class="container">
        <div class="footer-direction">
            <img [src]="cmsData.logoImageURL" alt="brand-logo" class="company-logo">
            <div *ngFor="let column of cmsData.columnsDFA" class="info-column">
                <h5 [routerLink]="column.location || ''">{{column.label}}</h5>
                <ul class="list-unstyled">
                    <li *ngFor="let row of column.items"><a [routerLink]="row.location || ''">{{row.label}}</a></li>
                </ul>
            </div>
            <div class="social-networks">
                <oc-social-links [socialLinks]="socialLinks"></oc-social-links>
            </div>
        </div>
        <h6 class="bottom-info">© 2020 Your company. All rights reserved.</h6>
    </div>
</div>
