<div class="home__hero bg-container bg-a">
    <div class="container d-flex flex-column align-items-center justify-content-center">
        <h1 class="home__hero-title text-center">{{cmsData.pageInfoTitle}}</h1>
        <p class="home__hero-description text-secondary text-center">{{cmsData.pageInfoSubtext}}</p>
        <oc-button class="home__hero-partner"
                   type="primary"
                   text="Become a Partner"></oc-button>
    </div>
</div>
<div class="home__services container d-flex justify-content-between flex-column flex-md-row">
    <section class="home__services-intro col-md-5">
        <h4 class="home__services-title">Why list on the OpenChannel Marketplace?</h4>
        <p class="home__services-description text-secondary">
            Build traction for your open source project or software
            company by listing your 1-Click Application in the Open Channel Marketplace.
        </p>
        <img class="home__services-image img-fluid article-image"
             src="../../../assets/img/temporary_home_pic.png" alt="">
    </section>
    <div class="home__services-list col-md-5">
        <div class="home__services-card feature-card">
            <div class="row mx-0 align-items-center flex-nowrap">
                <div class="feature-icon">
                    <img src="../../../assets/img/app-icon1.svg" alt="">
                </div>
                <p class="font-med ml-2 mb-0">Reach more users</p>
            </div>
            <p class="home__services-card-description text-secondary mt-2 mb-0">
                Open Channel global community of 4 million developers relies heavily on open source
                and free software when getting started. The Open Channel Marketplace helps them easily
                find and launch your project or product.
            </p>
        </div>
        <div class="home__services-card feature-card">
            <div class="row mx-0 align-items-center flex-nowrap">
                <div class="feature-icon">
                    <img src="../../../assets/img/icon-placeholder.svg" alt="">
                </div>
                <p class="font-med ml-2 mb-0">Create new revenue streams</p>
            </div>
            <p class="home__services-card-description text-secondary mt-2 mb-0">
                You can sell licenses directly to users now and, we plan to make new billing
                options available to help you create new and easier revenue streams for your business or projects.
            </p>
        </div>
        <div class="home__services-card feature-card">
            <div class="row mx-0 align-items-center flex-nowrap">
                <div class="feature-icon">
                    <img src="../../../assets/img/icon-placeholder2.svg" alt="">
                </div>
                <p class="font-med ml-2 mb-0">Find new committers</p>
            </div>
            <p class="home__services-card-description text-secondary mt-2 mb-0">
                Putting your 1-Click App in the DigitalOcean Marketplace introduces your
                project to more developers, which can lead to more people supporting and committing
                to it down the road.
            </p>
        </div>
    </div>
</div>
<div class="home__technology bg-container bg-a">
    <div class="container d-flex flex-column align-items-center justify-content-center">
        <h4 class="home__technology-title text-center">
            How developers are using the Open Channel <br> Marketplace?
        </h4>
        <div class="home__technology-list row">
            <div class="home__technology-card p-2">
                <div class="row mx-0 align-items-center">
                    <div class="technology-icon">
                        <img src="../../../assets/img/app-icon1.svg" alt="">
                    </div>
                    <p class="font-med ml-2 mb-0">Plesk</p>
                </div>
                <p class="text-secondary mt-2 mb-1 technology-description">
                    Plesk's free 1-Click Application enabled the company
                    to capture thousands of new users in record time.
                </p>
                <a class="cursor-pointer">
                    <span class="text-primary font-m font-med pr-1">See Case</span>
                    <img alt="icon" src="./assets/img/arrow-right.svg">
                </a>
            </div>
            <div class="home__technology-card p-2">
                <div class="row mx-0 align-items-center">
                    <div class="technology-icon">
                        <img src="../../../assets/img/app-icon.svg" alt="">
                    </div>
                    <p class="font-med ml-2 mb-0">Hasura GraphQL</p>
                </div>
                <p class="text-secondary mt-2 mb-1 technology-description">
                    As a new startup, Hasura enjoys greater exposure and
                    faster user adoption for their GraphQL engine, which brings blazing fast…
                </p>
                <a class="cursor-pointer">
                    <span class="text-primary font-m font-med pr-1">See Case</span>
                    <img alt="icon" src="./assets/img/arrow-right.svg">
                </a>
            </div>
            <div class="home__technology-card p-2">
                <div class="row mx-0 align-items-center">
                    <div class="technology-icon">
                        <img src="../../../assets/img/app-icon3.svg" alt="">
                    </div>
                    <p class="font-med ml-2 mb-0">Zulip</p>
                </div>
                <p class="text-secondary mt-2 mb-1 technology-description">
                    Zulip users can get an open source chat server
                    online in just minutes using the Open Channel Marketplace.
                </p>
                <a class="cursor-pointer">
                    <span class="text-primary font-m font-med pr-1">See Case</span>
                    <img alt="icon" src="./assets/img/arrow-right.svg">
                </a>
            </div>
        </div>
    </div>
</div>
<div class="home__approach container d-flex flex-column">
    <h4 class="home__approach-title row">Why list on the OpenChannel Marketplace?</h4>
    <div class="row justify-content-between">
        <div class="home__approach-card col-md-5">
            <div class="home__approach-card-heading align-items-center flex-nowrap">
                <span class="list-number">1</span>
                <p class="home__approach-card-name font-med">Register to become a Marketplace Vendor</p>
            </div>
            <p class="home__approach-card-text text-secondary">
                Just click <a href="" class="home__approach-card-link link font-med">Become a Seller</a> and give us a few pieces
                of information about your 1-Click
                App idea and your company. We'll respond with next steps or additional questions.
            </p>
        </div>
        <div class="home__approach-card col-md-5">
            <div class="home__approach-card-heading align-items-center flex-nowrap">
                <span class="list-number">2</span>
                <p class="home__approach-card-name font-med">Build your image</p>
            </div>
            <p class="home__approach-card-text text-secondary">
                Quickly and simply build a VM image on Open Channel manually,
                or automate via Fabric and Packer, <a href="" class="home__approach-card-link link font-med">
                then check its quality and readiness with our tools.</a>
                Or you can simply build a Kubernetes app with a single
                <a href="" class="home__approach-card-link link font-med">PR on our repo.</a>
            </p>
        </div>
    </div>
    <div class="row justify-content-between">
        <div class="home__approach-card col-md-5">
            <div class="home__approach-card-heading align-items-center flex-nowrap">
                <span class="list-number">3</span>
                <p class="home__approach-card-name font-med">Give us the details about your listing</p>
            </div>
            <p class="home__approach-card-text text-secondary">
                Provide the details and your 1-Click Apps on Droplet VMs and Open Channel
                Kubernetes will then be made available to all Open Channel customers once approved.
                (We plan to support listings of SaaS applications soon, too.)
            </p>
        </div>
        <div class="home__approach-card col-md-5">
            <div class="home__approach-card-heading align-items-center flex-nowrap">
                <span class="list-number">4</span>
                <p class="home__approach-card-name font-med">Engage with the community</p>
            </div>
            <p class="home__approach-card-text text-secondary">
                We encourage you to connect with your users by sharing links to
                forums or other support channels within your Marketplace listing.
                Take advantage of the opportunity to hear what your users want out of your
                product or project. And don't forget to send users to your new 1-Click App listing.
            </p>
        </div>
    </div>
</div>
<div class="home__register bg-container bg-a">
    <div class="container">
        <oc-app-get-started [getStartedHeader]="cmsData.bottomCalloutHeader"
                            [getStartedImage]="cmsData.bottomCalloutImageURL"
                            [getStartedDescription]="cmsData.bottomCalloutDescription"
                            [getStartedButtonText]="cmsData.bottomCalloutButtonText"
                            (getStarted)="router.navigate([cmsData.bottomCalloutButtonLocation || ''])">
        </oc-app-get-started>
    </div>
</div>

